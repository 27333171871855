import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import { leadStages, sidebarMap } from "../../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  getProgramList,
  getStudentLeads,
  ps_getStudentLeads,
} from "../../../api/partner";
import { ProgramList, StudentLeads } from "../../../types";
import Loader from "../../../components/Loader";
import { getDate } from "../../../utility/helper";
import { useNavigate } from "react-router-dom";
import SideBarB2AC from "../../../components/SideBarB2A_C";

interface TrackLeadsConsultantB2AProps {
  permissions: Record<number, number[]> | undefined;
}

const TrackLeadsConsultantB2A: React.FC<TrackLeadsConsultantB2AProps> = ({
  permissions,
}: TrackLeadsConsultantB2AProps) => {
  const [active, setActive] = useState(sidebarMap.TRACK_C_LEADS);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [partners, setPartners] = useState<StudentLeads[]>([]);
  const params = new URLSearchParams(window.location.search);

  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [leadStage, setLeadStage] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [trigger, setTrigger] = useState<boolean>(false);
  const [programList, setProgramList] = useState<ProgramList[]>([]);
  const [program, setProgram] = useState<string>("");
  const [owners, setOwners] = useState<string[]>([]);
  const [owner, setOwner] = useState<string>();
  const navigate = useNavigate();

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await ps_getStudentLeads({
        rowsPerPage,
        page,
        sDate,
        eDate,
        leadStage,
        search,
        program,
        owner,
      });
      if (response) {
        const value = response?.data?.data;
        setTotalCount(value?.totalLeads);
        setPartners(value?.leads);
        setName(value.name);
        setOwners(value.owners);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, owner, eDate, leadStage, trigger, program]);

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getProgramList();
      if (response) {
        const value = response?.data?.data;
        setProgramList(value.programs);
      }

      setLoading(false);
    };

    getPartners();
  }, []);

  return (
    <div>
      <SideBarB2AC
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="filters filter-3" style={{ marginTop: "0" }}>
            <div>
              <div className="filter_headers"> Select Course </div>
              <select
                onChange={(e) => {
                  setProgram(e.target.value);
                }}
                value={program}
                className="filter_inputs"
              >
                <option value="">All</option>
                {programList &&
                  programList.map((elem, i) => {
                    return (
                      <option value={elem.leadsquareProgramKey} key={i}>
                        {elem.leadsquareProgramKey}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div>
              <div className="filter_headers">From Date</div>
              <input
                type="date"
                className="filter_inputs"
                value={sDate}
                onChange={(e) => {
                  setsDate(e.target.value);
                  // getAllPartnerLeads();
                }}
              />
            </div>
            <div>
              <div className="filter_headers">To Date</div>
              <input
                type="date"
                className="filter_inputs"
                value={eDate}
                disabled={sDate ? false : true}
                onChange={(e) => {
                  seteDate(e.target.value);
                  // getAllPartnerLeads();
                }}
              />
            </div>
          </div>

          <div className="stats">
            <div>
              <div className="stats_head">Total Leads Generated</div>
              <div className="stats_stat">{totalCount && totalCount}</div>
            </div>

            <div>
              <div className="stats_head">Total Leads Converted</div>
              <div className="stats_stat">N/A</div>
            </div>

            <div>
              <div className="stats_head">Total Eligible Leads for Payout </div>
              <div className="stats_stat">N/A</div>
            </div>
          </div>

          <div className="filter-3">
            <div>
              <div className="filter_headers"> Select Lead Stage</div>
              <select
                onChange={(e) => {
                  setLeadStage(e.target.value);
                }}
                value={leadStage}
                className="filter_inputs"
              >
                <option value="">All</option>
                {leadStages &&
                  leadStages.map((elem, i) => {
                    return (
                      <option value={elem} key={i}>
                        {elem}
                      </option>
                    );
                  })}
              </select>
            </div>

            {/* <div>
              <div className="filter_headers"> Select Lead Owner</div>
              <select
                onChange={(e) => {
                  setOwner(e.target.value);
                }}
                value={owner}
                className="filter_inputs"
              >
                <option value="">All</option>
                {owners &&
                  owners.map((elem, i) => {
                    return (
                      <option value={elem} key={i}>
                        {elem}
                      </option>
                    );
                  })}
              </select>
            </div> */}
          </div>

          <div className="application_header mt-1">
            All Leads{" "}
            <Tooltip title="Here you can track partner leads">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                alt="profile"
                className="ml-1"
              />
            </Tooltip>
          </div>
          <hr />

          <div className="search_newapp mt-1">
            <div className="flex">
              <div>
                <input
                  type="text"
                  className="search_filters ml-1 ph-1"
                  placeholder={`Enter Name, Email or Phone`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                {search && search.trim() && (
                  <button
                    className="form_back ml-1"
                    onClick={() => {
                      setTrigger(!trigger);
                    }}
                  >
                    Search
                  </button>
                )}
                <button
                  className="form_back ml-1"
                  onClick={() => {
                    setSearch("");
                    setLeadStage("");
                    seteDate("");
                    setsDate("");
                    setTrigger(!trigger);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {partners && partners.length > 0 ? (
                <div className="">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    <Table className="table_p">
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Learner Name
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Learner Email ID
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          Learner Mobile{" "}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Generated On{" "}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Source{" "}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Source Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Stage
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Created by
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Training Location
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {partners &&
                          partners.map((elem, i) => {
                            return (
                              <TableRow className="table_row">
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.FirstName + " "} {elem.LastName}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.EmailAddress}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.Phone}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {getDate(elem.createdAt?.value)}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.mx_Program_3}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.Source || elem.SOURCE}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.OwnerIdEmailAddress}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.ProspectStage}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.CreatedByName}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.mx_Training_Location}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">Oops! Nothing found here</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackLeadsConsultantB2A;
