const Loader: React.FC = () => {
  return (
    <div className={`visible_loader`}>
      <div className={`loaderHeight`}>
        <div
          className={`rotate360`}
          style={{
            background:
              "url(https://cdn.eckovation.com/academy/Imarticus_Loader_Circle-02.svg) 49.72% no-repeat",
            width: "120px",
            margin: "auto",
            height: "150px",
          }}
        />
        <div
          style={{
            height: "100px",
            width: "100px",
            position: "absolute",
            margin: "auto",
            background:
              "url(https://cdn.pegasus.imarticus.org/imarticus_2/imarticus_logo_loader_new.svg) center no-repeat",
            backgroundSize: "auto",
            left: "calc(50% - 50px)",
            top: "30px",
          }}
        ></div>
      </div>
    </div>
  );
};
export default Loader;
