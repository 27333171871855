const configs = require("../configs/configs");

const baseURL = configs.routes.BASE_URL;

export const ROUTES = {
  login: baseURL + "/account/login",
  logout: baseURL + "/auth/logout",
  reqEmailOtp: baseURL + "/account/req_otp_email",
  verifyOTP: baseURL + "/account/verify_otps",
  setPasswordRoute: baseURL + "/account/set_password",
  partnerPermission: baseURL + "/partners/get_partner_permission",
  getFormBlocks: baseURL + "/partners/get_form_blocks",
  createFormRoute: baseURL + "/partners/create_form",
  editFormRoute: baseURL + "/partners/edit_form",
  getSingleApplicat: baseURL + "/partners/create_form",
  getAllFormRoute: baseURL + "/partners/get_all_form",
  getSingleApplicationRoute: baseURL + "/partners/get_single_application",
  createTypesRoute: baseURL + "/partners/create_types",
  getAllPartnerTypesRoute: baseURL + "/partners/get_all_partner_types",
  createSGroute: baseURL + "/partners/create_sales_group",
  getAllSGroute: baseURL + "/partners/get_all_sales_group",
  createPartnerLeads: baseURL + "/partners/c_p_a_lead",
  rejectPartnerLead: baseURL + "/partners/r_p_a_lead",
  partnerLead: baseURL + "/partners/get_partner_leads",
  sendApplication: baseURL + "/partners/send_application",
  getPartnerApplication: baseURL + "/partners/get_applications",
  getApplicantApplicationRoute:
    baseURL + "/partners/get_applicants_application",
  updateApplicationSlugRoute: baseURL + "/partners/u_s_application",
  submitApplicationRoute: baseURL + "/partners/s_application",
  getFlagsRoute: baseURL + "/partners/g_p_flags",
  createFlagRoute: baseURL + "/partners/c_p_flags",
  rejectPartnersRoute: baseURL + "/partners/reject_partners",
  approvePartnerRoute: baseURL + "/partners/approve_partner",
  uploadAgreementRoute: baseURL + "/partners/upload_agreement",
  getAllPartners: baseURL + "/partners/g_partners",
  verifyOTPAndCreatePartnerRoute: baseURL + "/partners/v_otp_c_p",
  getAllStudentLeadsRoute: baseURL + "/partners/getStudentLeads",
  partnerSideLeadsRoute: baseURL + "/partners/ps_getStudentLeads",
  uploadFormFilesRoute: baseURL + "/partners/uploadImgtoS3",
  sendReminderRoute: baseURL + "/partners/send_reminder",
  inactive_partnerRoute: baseURL + "/partners/inactive_partner",
  getProgramListRoute: baseURL + "/partners/get_programs_list",
  verifyAadhaarRoute: baseURL + "/partners/verifyaadhar",
  exportApplicationRoute: baseURL + "/partners/export_application",
  getAllUserRoute: baseURL + "/partners/get_all_users",
  vrfyUserEmailRoute: baseURL + "/partners/vrfy_user_ops",
  createOpsUserRoute: baseURL + "/partners/create_ops_user",
  deleteOpsUserRoute: baseURL + "/partners/delete_user_ops",
  getB2APartnersRoute: baseURL + "/partners/get_b2a_partners",
  adminOtpRoute: baseURL + "/partners/adminOtp",
  adminOtpBlukRoute: baseURL + "/partners/adminOtpBulk",
  uploadBulkPartnersRoute: baseURL + "/partners/uploadBulkPartners",
  getConsultantProfileRoute: baseURL + "/partners/getConsultantProfile",
  getLeadRequestsRout5e: baseURL + "/partners/getLeadRequests",
  getCRequestsRoute: baseURL + "/partners/get_c_requests",
  createNewLeadRoute: baseURL + "/partners/c_d_lead",
  getSingleRequestLeadRoute: baseURL + "/partners/g_s_r_leads",
  uploadLeadsRoute: baseURL + "/partners/uploadLeads",
  updateRequestRoute: baseURL + "/partners/updateRequest",
  aadhaarVrfyStartRoute: baseURL + "/partners/verifyaadhar",
  aadhaarReqOTPRoute: baseURL + "/partners/request_aadhar_otp",
  aadhaarVrfyRoute: baseURL + "/partners/verify_aadhar_otp",
  digiLockerInit: baseURL + "/partners/digiLockerInit",
  digiLockerCheck: baseURL + "/partners/digiLockerCheck",
  getUserDetailsRoute: baseURL + "/partners/getUserDetails",
  editSGRoute: baseURL + "/partners/editSG",
};
