import { useEffect, useState } from "react";
import { emailLoginErrorMap } from "../../constants/constants";
import { loginWithEmail } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

interface LoginWithEmailProps {
  setMethod: React.Dispatch<React.SetStateAction<string>>;
  loginMethods: {
    EMAIL: string;
    OTP: string;
    FORGOT: string;
  };
}

const LoginWithEmail: React.FC<LoginWithEmailProps> = ({
  setMethod,
  loginMethods,
}: LoginWithEmailProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const sumbitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoader(true);

    if (!email.trim()) {
      setError(emailLoginErrorMap.NO_EMAIL);
      return;
    }

    if (!password.trim()) {
      setError(emailLoginErrorMap.NO_PASSWORD);
      return;
    }

    const response = await loginWithEmail({ email, password });

    if (response) {
      if (response.status === 200) {
        setLoader(false);
        navigate("/dashboard");
      } else {
        setLoader(false);
        setError(emailLoginErrorMap.WRONG_CREDENTIALS);
      }
    } else {
      setError(emailLoginErrorMap.WRONG_CREDENTIALS);
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div>
        <div className="login_form_header">Login </div>
        <div className="login_form_des">
          Before moving forward, kindly login!
        </div>

        {error && <div className="login_error">{error}</div>}

        <form onSubmit={sumbitHandler}>
          <div className="login_labels">Enter Email Id</div>
          <input
            type="email"
            value={email}
            className="login_inputs"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onClick={() => {
              setError("");
            }}
            required={true}
          />

          <div className="login_labels">Enter Password</div>
          <input
            type="password"
            className="login_inputs"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onClick={() => {
              setError("");
            }}
            required={true}
          />

          <button className="login_btn" type="submit" style={{ width: "100%" }}>
            Log In
          </button>
        </form>

        <div className="login_switch">
          <div
            className="login_switch_btn"
            onClick={() => setMethod(loginMethods.OTP)}
          >
            Login with OTP
          </div>
          <div
            className="login_switch_btn"
            onClick={() => setMethod(loginMethods.FORGOT)}
          >
            Forgot Password
          </div>
        </div>
        <hr />
        <div className="login_adm_btn">
          <button
            className="login_switch_adm_btn"
            onClick={() => {
              window.open(
                "https://imarticus.org/imarticus-edtech-entrepreneur/"
              );
            }}
          >
            Not a Partner?
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginWithEmail;
