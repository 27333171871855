import { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import {
  leadsSources,
  partnerLeadStatus,
  sidebarMap,
} from "../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  createPartnerLeads,
  getAllPartnerTypes,
  getPartnerLeads,
  rejectPartnerLead,
} from "../../api/partner";
import { Lead, PartnerTypes, SalesGroup } from "../../types";

import Loader from "../../components/Loader";
import { checkDeepVisibility, getDate } from "../../utility/helper";
import {
  CountryCodes,
  LEADSQUARE_CENTER_LOCATIONS,
  LEADSQUARE_STATE_LOCATIONS,
} from "../../utility/utils";

interface PartnerLeadsProps {
  permissions: Record<number, number[]> | undefined;
}

const PartnerLeads = ({ permissions }: PartnerLeadsProps) => {
  const [active, setActive] = useState<string>(sidebarMap.PARTNER_LEADS);

  const [loading, setLoading] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [search, setSearch] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [create, setCreate] = useState<boolean>(false);

  const [location, setLocation] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [ccod, setCCOD] = useState<string>("91");
  const [mobile, setMobile] = useState<string>("");
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<string>("");
  const [targetLead, setTargetLead] = useState<string>("");
  const [selectPT, setSelectPT] = useState<PartnerTypes | null>();
  const [apEmail, setAPEmail] = useState<string>("");
  const [otpModal, setOTPModal] = useState<Boolean>(false);
  const [otp, setOTP] = useState<string>();
  const [leadSource, setLeadSource] = useState<string>("");
  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");
  const [qLeadStatus, setQleadStatus] = useState<string>("");
  const [qLS, setQLS] = useState<string>("");
  const [qType, setQType] = useState<string>("");
  const [qSG, setqSG] = useState<string>("");

  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);
  const [selectSg, setSelectSG] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectSg>) => {
    const {
      target: { value },
    } = event;

    setSelectSG(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const toFind: any = {
      rowsPerPage,
      page,
    };

    if (search && search.trim()) {
      toFind["search"] = search.trim();
    }

    if (sDate && sDate.trim()) {
      toFind["sDate"] = sDate;
    }

    if (eDate && eDate.trim()) {
      toFind["eDate"] = eDate;
    }

    if (qLeadStatus) {
      toFind["status"] = qLeadStatus;
    }

    if (qLS) {
      toFind["qLS"] = qLS;
    }

    if (qSG) {
      toFind["qSG"] = qSG;
    }

    if (qType) {
      toFind["qType"] = qType;
    }

    const getAllPartnerLeads = async () => {
      const response = await getPartnerLeads(toFind);

      if (response?.status === 200) {
        let value = response?.data?.data;
        setLeads(value.leads);
        setTotalCount(value.totalLeads);
        // setLeadStatus(value.leadStatus);
        setAllSgs(value.sgNames);
      }
      setLoading(false);
    };

    getAllPartnerLeads();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, trigger, eDate, qLeadStatus, qLS, qSG, qType]);

  useEffect(() => {
    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage: 0, page: 0 });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setPartnerTypes(value.partnerTypes);
      }
    };
    allTypes();
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !name?.trim() ||
      !email?.trim() ||
      !ccod?.trim() ||
      !state?.trim() ||
      !mobile?.trim() ||
      !location?.trim() ||
      !partnerType?.trim() ||
      !(selectSg.length > 0)
    ) {
      alert("All fields are mandatory");
      return;
    } else {
      setLoading(true);
      const response = await createPartnerLeads({
        name,
        location,
        state,
        ccod,
        mobile,
        email,
        type_id: partnerType,
        apEmail: apEmail,
        leadSource: leadSource,
        sales_grp: selectSg,
      });

      if (response?.status === 200) {
        alert("Partner Lead Created!");
        empty();
        setCreate(false);
        setTrigger(!trigger);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  const rejectHandler = async (id: string) => {
    if (window.confirm("Are you sure you want to reject this application?")) {
      setLoading(true);
      const response = await rejectPartnerLead(id);
      if (response?.status === 200) {
        setTrigger(!trigger);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  // const sendApplicationHandler = async (
  //   e: React.FormEvent<HTMLFormElement>
  // ) => {
  //   e.preventDefault();
  //   if (
  //     !name?.trim() ||
  //     !email?.trim() ||
  //     !ccod?.trim() ||
  //     !state?.trim() ||
  //     !mobile?.trim() ||
  //     !location?.trim() ||
  //     !partnerType?.trim()
  //   ) {
  //     alert("All fields are mandatory");
  //     return;
  //   } else {
  //     setLoading(true);
  //     const response = await sendApplicationtoUser({
  //       partner_lead_id: targetLead,
  //       // type_id: partnerType,
  //       // apEmail: apEmail,
  //       // leadSource: leadSource,
  //     });

  //     if (response?.status === 200) {
  //       if (response?.data?.data?.otp) {
  //         setOTPModal(true);
  //         setAPEmail(response?.data?.data?.email);
  //       } else {
  //         alert("Application Sent");
  //         empty();
  //         setTrigger(!trigger);
  //       }
  //     } else if (response?.status === 420) {
  //       alert(response?.data.message);
  //     }
  //     setLoading(false);
  //   }
  // };

  const empty = () => {
    setName("");
    setMobile("");
    setEmail("");
    setLocation("");
    setState("");
    setTargetLead("");
    setPartnerType("");
    setAPEmail("");
    setLeadSource("");
    setSelectPT(null);
    setSelectSG([]);
    setqSG("");
    setQType("");
    setSearch("");
    setsDate("");
    seteDate("");
    setQleadStatus("");
  };

  return (
    <div>
      <SideBar
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        {loading ? (
          <Loader />
        ) : (
          <div className="partner_leads">
            {/* {otpModal && (
              <div className="popupModal ">
                <div className="popupModal_main w30">
                  <div className="popupModal_header">
                    <span></span>
                    <img
                      src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                      alt="cross"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOTPModal(false);
                      }}
                    />
                  </div>
                  <div className="popupModal_body center-align">
                    <img
                      src="https://cdn.pegasus.imarticus.org/b2b/OTP.svg"
                      alt="cross"
                      style={{ cursor: "pointer" }}
                    />
                    <span className="mt-1">OTP is valid upto 30 min</span>
                    <input
                      type="number"
                      className="popupModal_input w-50 mt-1 text-center"
                      required={true}
                      value={otp}
                      placeholder="0-0-0-0-0-0"
                      onChange={(e) => {
                        setOTP(e.target.value);
                      }}
                    />

                    <span className="mt-1">
                      OTP has been sent on your email <br /> {apEmail}
                    </span>

                    <button
                      onClick={() => {
                        verifyOTP();
                      }}
                      className="pm_create w-50 mt-1 mb-3"
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              </div>
            )} */}

            {create && (
              <div className="popupModal">
                <div className="popupModal_main">
                  <div className="popupModal_header">
                    <span>Create Partner </span>
                    <img
                      src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                      alt="cross"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCreate(false);
                        empty();
                      }}
                    />
                  </div>
                  <hr />
                  <div className="popupModal_body">
                    <form onSubmit={submitHandler}>
                      <div className="popupModal_form">
                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Partner Full Name <span className="red">*</span>
                          </span>
                          <input
                            type="text"
                            className="popupModal_input"
                            required={true}
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Enter Email ID <span className="red">*</span>
                          </span>
                          <input
                            type="email"
                            className="popupModal_input"
                            required={true}
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>

                        <div className="popupModal_fields popupModal_ccod_wrapper">
                          <span className="popupModal_label">
                            Phone Number <span className="red">*</span>
                          </span>
                          <div
                            className="popupModal_ccod"
                            style={{ background: "#fff" }}
                          >
                            <select
                              className="popupModal_input ccod_input"
                              value={ccod}
                              onChange={(e) => {
                                setCCOD(e.target.value);
                              }}
                              required={true}
                            >
                              <option value="">Country</option>
                              {CountryCodes &&
                                CountryCodes.map((elem, i) => {
                                  return (
                                    <option key={i} value={elem.value}>
                                      {elem.display}
                                    </option>
                                  );
                                })}
                            </select>

                            <input
                              type="number"
                              required={true}
                              value={mobile}
                              maxLength={10}
                              minLength={10}
                              className="popupModal_input mob_input"
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            State <span className="red">*</span>
                          </span>

                          <select
                            className="popupModal_input "
                            value={state}
                            required={true}
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                          >
                            <option value="">Select State</option>
                            {LEADSQUARE_STATE_LOCATIONS &&
                              LEADSQUARE_STATE_LOCATIONS.map((elem, i) => {
                                return (
                                  <option value={elem} key={i}>
                                    {elem}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Location <span className="red">*</span>
                          </span>

                          <select
                            className="popupModal_input "
                            value={location}
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                            required={true}
                          >
                            <option value="">Select Location</option>
                            {LEADSQUARE_CENTER_LOCATIONS &&
                              LEADSQUARE_CENTER_LOCATIONS.map((elem, i) => {
                                return (
                                  <option value={elem} key={i}>
                                    {elem}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Type <span className="red">*</span>
                          </span>
                          <select
                            className="popupModal_input "
                            required={true}
                            value={partnerType}
                            onChange={(e) => {
                              setPartnerType(e.target.value);

                              const selected = partnerTypes.filter(
                                (elem) => elem._id === e.target.value
                              );

                              setSelectPT(selected[0]);
                            }}
                          >
                            <option value="">Select Type</option>
                            {partnerTypes &&
                              partnerTypes.map((elem, i) => {
                                return (
                                  <option value={elem._id} key={i}>
                                    {elem.name}
                                  </option>
                                );
                              })}
                          </select>

                          {/* <div className="radio-group">
                            {partnerTypes &&
                              partnerTypes.map((elem, i) => {
                                return (
                                  <label className="custom-radio" key={i}>
                                    <input
                                      type="radio"
                                      name="option"
                                      value={elem._id}
                                      onChange={(e) => {
                                        const target =
                                          e.target as HTMLInputElement;
                                        setPartnerType(target.value);

                                        const selected = partnerTypes.filter(
                                          (elem) => elem._id === target.value
                                        );

                                        setSelectPT(selected[0]);
                                      }}
                                    />
                                    <span className="radio-btn"></span>
                                    {elem.name}
                                  </label>
                                );
                              })}
                          </div> */}
                        </div>

                        {selectPT && selectPT.parent && (
                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Associated Partner Email{" "}
                              <span className="red">*</span>
                            </span>
                            <input
                              type="email"
                              className="popupModal_input"
                              required={selectPT.parent}
                              value={apEmail}
                              onChange={(e) => setAPEmail(e.target.value)}
                            />
                          </div>
                        )}

                        {selectPT && selectPT.lead_source && (
                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Lead Source <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={selectPT.lead_source}
                              value={leadSource}
                              onChange={(e) => setLeadSource(e.target.value)}
                            />
                          </div>
                        )}

                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Sales Group <span className="red">*</span>{" "}
                            <Tooltip title="Partner and student leads will be visible to assigned sales group only">
                              <img
                                src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                                alt="profile"
                                style={{ width: "20px" }}
                              />
                            </Tooltip>
                          </span>
                          <FormControl sx={{ width: "95%" }}>
                            <Select
                              sx={{ height: "42px", fontSize: "12px" }}
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              className="custom_sg_select"
                              multiple
                              value={selectSg}
                              onChange={handleChange}
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {allSgs.map((elem, i) => (
                                <MenuItem key={i} value={elem.name}>
                                  <ListItemText primary={elem.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="popupModal_fields"></div>
                      </div>

                      <div className="popupModal_foot">
                        <button className="pm_create" type="submit">
                          Create
                        </button>
                        <button
                          className="pm_create pm_cancel"
                          onClick={() => {
                            setCreate(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            <div className="filters" style={{ marginTop: "0" }}>
              <div>
                <div className="filter_headers">From Date</div>
                <input
                  type="date"
                  className="filter_inputs"
                  value={sDate}
                  onChange={(e) => {
                    setsDate(e.target.value);
                    // getAllPartnerLeads();
                  }}
                />
              </div>
              <div>
                <div className="filter_headers">To Date</div>
                <input
                  type="date"
                  className="filter_inputs"
                  value={eDate}
                  disabled={sDate ? false : true}
                  onChange={(e) => {
                    seteDate(e.target.value);
                    // getAllPartnerLeads();
                  }}
                />
              </div>

              <div>
                <div className="filter_headers">Select Lead Source</div>
                <select
                  name="cars"
                  id="cars"
                  value={qLS}
                  className="filter_inputs"
                  onChange={(e) => {
                    setQLS(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value="1">Website</option>
                  <option value="2">Created</option>
                </select>
              </div>

              <div>
                <div className="filter_headers">Select Lead Status</div>
                <select
                  className="filter_inputs"
                  value={qLeadStatus}
                  onChange={(e) => {
                    setQleadStatus(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value="2">Invite Sent</option>
                  <option value="4">Onboarded</option>
                  <option value="5">Not Onboarded</option>
                </select>
              </div>
            </div>

            <div className="filters " style={{ marginTop: "0" }}>
              <div>
                <div className="filter_headers">Select Partner Type</div>
                <select
                  className="filter_inputs w-110"
                  value={qType}
                  onChange={(e) => {
                    setQType(e.target.value);
                  }}
                >
                  <option value="">All</option>

                  {partnerTypes &&
                    partnerTypes.map((elem, i) => {
                      return <option value={elem._id}>{elem.name}</option>;
                    })}
                </select>
              </div>

              <div>
                <div className="filter_headers">Select Sales Group</div>
                <select
                  className="filter_inputs  w-110"
                  value={qSG}
                  onChange={(e) => {
                    setqSG(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  {allSgs &&
                    allSgs.length > 0 &&
                    allSgs.map((elem, i) => {
                      if (elem._id !== "*") {
                        return (
                          <option value={elem._id} key={i}>
                            {elem.name}
                          </option>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                </select>
              </div>

              <div></div>
              <div></div>
            </div>

            <div className="partner_leads_header">All Partner Leads</div>
            <hr />
            <div className="mt-1 head_bars">
              <div>
                <input
                  type="text"
                  value={search}
                  className="search_filters ml-1 ph-1"
                  placeholder={`Search`}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <button
                  className="form_back ml-1"
                  onClick={() => {
                    setTrigger(!trigger);
                  }}
                >
                  Search
                </button>

                <button
                  className="form_back ml-1"
                  onClick={() => {
                    empty();
                    setTrigger(!trigger);
                  }}
                >
                  Reset
                </button>
              </div>

              <div>
                {checkDeepVisibility({
                  permissions: permissions,
                  resource: 2,
                  value: 2,
                }) && (
                  <button
                    onClick={() => {
                      setCreate(true);
                    }}
                    className="back_btn col-green"
                  >
                    Create Partner +
                  </button>
                )}
              </div>
            </div>
            {leads.length > 0 ? (
              <>
                <div>
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 75, 250, 500, 1000]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>

                <TableContainer
                  component={Paper}
                  style={{ width: "100%", marginTop: "1rem" }}
                >
                  <Table className="table_p">
                    <TableHead className="table_head">
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        S.No.{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Partner Name{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Partner Email ID
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Partner Mobile
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        City
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        State
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Type
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Sales Group
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Query
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Lead Created On
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Lead Source
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Partner Lead Source
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                        className=" trb"
                      >
                        Action
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {leads &&
                        leads.map((lead, i) => {
                          return (
                            <TableRow className=" trb" key={i}>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.name}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.email}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {/* +{lead.ccod} -  */}
                                {lead.phone}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.city}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.state}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.p_type}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.sg && lead.sg.length > 0 ? (
                                  <>
                                    {lead.sg.map((elem, i) => {
                                      return (
                                        <div key={i} className="sg_users">
                                          {elem}
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.query}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {getDate(lead.createdAt)}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {leadsSources[lead.source]}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {partnerLeadStatus[lead.status]}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.lead_source ? lead.lead_source : "-"}
                              </TableCell>
                              <TableCell
                                className="trb"
                                align="center"
                                style={{ minWidth: "100px" }}
                              >
                                {lead.status !== 5 && (
                                  <button
                                    className="bin"
                                    onClick={() => {
                                      rejectHandler(lead._id);
                                    }}
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/partnerimarticus/bin.svg"
                                      alt="bin"
                                    />
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div className="no_application">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                  alt="profile"
                  className=""
                />
                <span className="na_head">It's empty here!</span>
                <span className="na_desc">
                  Click on Create Partner to map the existing leads with a
                  partner
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerLeads;
