import { useState } from "react";
import { logout } from "../api/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

let cookies = new Cookies();

interface TopBarProps {
  active: string;
}

const TopBar: React.FC<TopBarProps> = ({ active }: TopBarProps) => {
  const navigate = useNavigate();
  const [drop, setDrop] = useState(false);

  return (
    <div className="topbar">
      <div className="topbar_info"> {active}</div>
      <div
        className="topbar_profile"
        onMouseEnter={() => {
          setDrop(true);
        }}
        onMouseLeave={() => {
          setDrop(false);
        }}
      >
        <img
          src="https://cdn.pegasus.imarticus.org/images/Profile-01.svg"
          alt="profile"
          className="topbar_pic"
        />
        <span>{cookies.get("Profile")?.nam || "USER"}</span>
        <img
          src="https://cdn.pegasus.imarticus.org/labs/down-arrow.svg"
          alt="arrow"
        />
      </div>
      {drop && (
        <div
          className="topbar_drop"
          onMouseEnter={() => {
            setDrop(true);
          }}
          onMouseLeave={() => {
            setDrop(false);
          }}
        >
          {/* <div>View Profile</div> */}
          <div
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
