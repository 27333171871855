import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllSG } from "../../api/partner";
import { SalesGroup } from "../../types";

import { getDate } from "../../utility/helper";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

interface ViewAllSalesGroupProps {
  setCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewAllSalesGroup = ({ setCreate }: ViewAllSalesGroupProps) => {
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [sg, setSG] = useState<SalesGroup[]>([]);

  const navigate = useNavigate();

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const getSG = async () => {
      const response = await getAllSG({ rowsPerPage, page });
      if (response) {
        const value = response?.data?.data;
        setTotalCount(value?.totalGroups);
        setSG(value?.salesGroups);
      }

      setLoading(false);
    };

    getSG();
  }, [page, rowsPerPage]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="partner_leads_header">
            All Sales Group
            <div>
              <button
                onClick={() => {
                  setCreate(true);
                }}
                className="back_btn col-green"
              >
                + Create Sales Group
              </button>
            </div>
          </div>
          <hr />

          {sg && sg.length > 0 ? (
            <>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 75, 250, 500, 1000]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
              <TableContainer
                component={Paper}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Table>
                  <TableHead className="table_head">
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      S No.
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      State
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Date Created
                    </TableCell>

                    {/* <TableCell className="trb" align="center" style={{ minWidth: "100px" }}>
                      Actions
                    </TableCell> */}
                  </TableHead>
                  <TableBody>
                    {sg.length > 0 &&
                      sg.map((elem, i) => {
                        return (
                          <TableRow className="table_row" key={i}>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {i + 1}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.name}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.location}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.state}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {getDate(elem.createdAt)}
                            </TableCell>
                            {/* 
                            <TableCell className="trb" align="center" style={{ minWidth: "100px" }}>
                              <button
                                className="table_edit"
                                onClick={() => {
                                  navigate("/e_s_grp?id=" + elem._id);
                                }}
                              >
                                Edit
                              </button>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <div className="no_application">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                  alt="profile"
                  className=""
                />
                <span className="na_head">It's empty here!</span>
                <span className="na_desc">
                  Click on Create Partner to map the existing leads with a
                  partner
                </span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewAllSalesGroup;
