import Axios from "axios";
import { ROUTES } from "../constants/routes";
import Cookies from "universal-cookie";

const configs = require("../configs/configs");

let cookies = new Cookies();

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 498) {
      cookies.remove("isLoggedIn", {
        path: configs.config.PATH,
        domain: configs.config.DOMAIN,
      });
      window.location.replace(configs.routes.WEBSITE_LINK);
    }
    return Promise.reject(error);
  }
);

interface LoginCookiesProps {
  at: string;
  aid: string;
  secret: string;
  rt: string;
  isLoggedIn: boolean;
  pid: string;
  sid: string;
  user: string;
  profile: string;
}

export const setLoginCookies = ({
  at,
  aid,
  secret,
  rt,
  isLoggedIn,
  pid,
  sid,
  user,
  profile,
}: LoginCookiesProps) => {
  let expirydate = new Date();
  expirydate.setDate(expirydate.getDate() + 180);

  cookies.set("at", at, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("aid", aid, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("secret", secret, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("rt", rt, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("isLoggedIn", isLoggedIn, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("pid", pid, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("s_id", sid, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("user", user, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
  cookies.set("Profile", profile, {
    expires: expirydate,
    domain: configs.config.DOMAIN,
    path: "/",
  });
};

interface LoginWithEmailParams {
  email: string;
  password: string;
}

export const loginWithEmail = async ({
  email,
  password,
}: LoginWithEmailParams) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const data = {
    auth_method: "email",
    email: email,
    password: password,
  };

  try {
    const res = await Axios.post(ROUTES.login, data, config);
    if (res) {
      if (res.status === 200) {
        let data = res.data.data;
        setLoginCookies({
          at: data.at,
          aid: data.aid,
          secret: data.secret,
          rt: data.rt,
          isLoggedIn: true,
          pid: data.pid,
          sid: data.sid,
          user: data.user,
          profile: data.Profile[0],
        });

        return res;
      } else {
        return res;
      }
    } else {
      console.warn("Something went wrong");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

interface loginWithEmailOTPprops {
  email: string;
}

export const loginWithEmailOTP = async ({ email }: loginWithEmailOTPprops) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const data = {
    email: email,
  };

  try {
    const res = await Axios.post(ROUTES.reqEmailOtp, data, config);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        return res;
      }
    } else {
      console.warn("Something went wrong");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

interface verifyOTPprops {
  otp: string;
  aid: string;
}

export const verifyOTP = async ({ otp, aid }: verifyOTPprops) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const data = {
    e_otp: otp,
    aid: aid,
  };

  try {
    const res = await Axios.post(ROUTES.verifyOTP, data, config);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        return res;
      }
    } else {
      console.warn("Something went wrong");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

interface setPasswordProps {
  cnfPass: string;
  passwrd: string;
  aid: string;
}

export const setPasswordUser = async ({
  cnfPass,
  passwrd,
  aid,
}: setPasswordProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const data = {
    cnfPass: cnfPass,
    aid: aid,
    passwrd: passwrd,
  };

  console.log(data);

  try {
    const res = await Axios.post(ROUTES.setPasswordRoute, data, config);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        return res;
      }
    } else {
      console.warn("Something went wrong");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const logout = async (): Promise<void> => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  cookies.remove("isLoggedIn", {
    path: configs.config.PATH,
    domain: configs.config.DOMAIN,
  });
  try {
    const res = await Axios.post(ROUTES.logout, { cl: "W" }, config);
    if (res) {
      window.open("/", "_self");
    } else {
      console.warn("Something went wrong");
    }
  } catch (error) {
    console.log(error);
    return;
  }
};
